import React from "react"
import Navbar from "../../components/Navbar/Navbar"

export default function Home() {
  return(
    <div>
      <h1>Welcome to blakeacarlson.com!</h1>
      <h3>This website is under development</h3>
    </div>
  )
}
